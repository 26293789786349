import './App.css';
import { ThemeProvider } from '@emotion/react';
import { Container, CssBaseline, Paper, ToggleButton, ToggleButtonGroup, createTheme } from '@mui/material';
import { Brightness7, DarkMode } from '@mui/icons-material';
import { useState } from 'react';
import Router from './components/Router';

function App() {
  const [ theme, setTheme ] = useState(true);
  const darkTheme = createTheme({
    palette: {
      mode: theme ? 'dark' : 'light',
    },
  });
  const handleChangeTheme = (event, value) => {
    setTheme(value);
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Paper variant="outlined" style={{ position: 'fixed', bottom: '1.45rem', left: '1.45rem', zIndex: 9999, display: 'none' }}>
        <ToggleButtonGroup onChange={handleChangeTheme} exclusive={true}>
          <ToggleButton value={false} key="" selected={!theme}>
            <Brightness7 />
          </ToggleButton>
          <ToggleButton color="primary" value={true} key="" selected={theme}>
            <DarkMode />
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>
      <Container maxWidth='xl'>
        <Router />
      </Container>
    </ThemeProvider>
  );
}

export default App;