import { useEffect, useState } from "react";
import { getVideos } from '../services/service';
import { Alert, Badge, Box, CircularProgress, Divider, Drawer, Fade, IconButton, styled, Toolbar, Typography, useTheme } from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import { ChevronLeft, ChevronRight, Menu, Star } from "@mui/icons-material";
import Player from "./Player";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import 'dayjs/locale/sk';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);
dayjs.locale('sk');

function Home() {
  const drawerWidth = 350;
  const theme = useTheme();
  const [ loading, setLoading ] = useState(true);
  const [ alert, setAlert ] = useState(false);
  const [ alertCollapse, setAlertCollapse ] = useState(false);
  const [ alertText, setAlertText ] = useState("");
  const [ startDate, setStartDate ] = useState(null);
  const [ endDate, setEndDate ] = useState(null);
  const [ open, setOpen ] = useState(true);
  const [ selectedDate, setSelectedDate ] = useState(null);
  const [ videos, setVideos] = useState([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, color }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
      {
        props: ({ open }) => open,
        style: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      },
    ],
    backgroundColor: color === 'info' ? theme.palette.info.dark : theme.palette.background.default,
  }));

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      variants: [
        {
          props: ({ open }) => open,
          style: {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          },
        },
      ],
    }),
  );

  const handleDateChange = (date) => {
    setSelectedDate(date ? dayjs(date) : null);
  };

  const renderDayWithIcon = (date) => {
    const formattedDate = date.day.format('YYYY-MM-DD');
    const isFavourite = videos[formattedDate] !== undefined && videos[formattedDate].favourite === 1;
    return (
      <Badge
        overlap="circular"
        badgeContent={isFavourite && !date.outsideCurrentMonth ? <Star fontSize="small" color="warning" /> : null}
      >
        <PickersDay {...date} />
      </Badge>
    );
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const data = await getVideos();
        setVideos(data.videos);
        setStartDate(dayjs(data.data.startDate));
        setEndDate(dayjs(data.data.endDate));
        setSelectedDate(dayjs(data.data.endDate));
      } catch (error) {
        setAlert(true);
        setAlertText(error.message);
        setAlertCollapse(true);
      } finally {
        setLoading(false);
      }
    };
    fetchVideos();
  }, []);

  return (
    <>
      {loading && 
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '100vh'
        }}
      >
        <CircularProgress color='info' size={200}/>
      </div>}
      {!loading && 
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={open} color="info">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={[
                { mr: 2 },
                open && { display: 'none' },
              ]}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Kojšovská hoľa - SHMU Kamera
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open} 
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sk">
            <DateCalendar
              minDate={startDate}
              maxDate={endDate}
              value={selectedDate}
              onChange={handleDateChange}
              views={['year', 'month', 'day']}
              slots={{ day: renderDayWithIcon }}
            />
          </LocalizationProvider>
          <Divider />
            <img 
              src="img/k.png" 
              alt="" 
              style={{
                opacity: 0.25,
                padding: '0rem 2rem',
                marginTop: 'auto'
              }}
            />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {alert &&
            <Fade in={alertCollapse}>
              <Alert 
                variant="filled"
                severity="error"
                sx={{
                  mb: 2,
                  width: '100%'
                }}
              >
                {alertText}
              </Alert>
            </Fade>
          }
          <Player date={selectedDate}/>
        </Main>
      </Box>}
    </>
  )
}

export default Home;