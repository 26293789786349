import ReactPlayer from "react-player";
import { getFileName, getVideoUrl } from "../services/utils";
import { useEffect, useRef, useState } from "react";
import { Divider, FormControlLabel, IconButton, Paper, Radio, RadioGroup } from "@mui/material";
import { Pause, PlayArrow, Repeat, Forward5, Replay5, Forward10, Replay10, Speed, Download } from "@mui/icons-material";
import { VIDEO_SPEED } from "../services/constants";
import fileDownload from "js-file-download";
import axios from "axios";

export default function Player({ date = null }) {
  const [ isReady, setIsReady ] = useState(false);
  const [ videoUrl, setVideoUrl ] = useState(getVideoUrl(date));
  const [ playing, setPlaying ] = useState(false);
  const [ loop, setLoop ] = useState(false);
  const [ speed, setSpeed ] = useState(VIDEO_SPEED.find((rate) => rate.default).speed);
  const playerRef = useRef(null);

  useEffect(() => {
    const newUrl = getVideoUrl(date);
    if (newUrl !== videoUrl) {
      setVideoUrl(newUrl);
      setIsReady(false);
    }
  }, [date, videoUrl]);

  const handleReady = () => {
    setIsReady(true);
  };

  const skipTime = (seconds) => {
    const currentTime = playerRef.current.getCurrentTime();
    playerRef.current.seekTo(currentTime + seconds, "seconds");
  };

  const handleEnd = () => {
    setPlaying(false);
  };

  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }

  if (!date) {
    return "";
  }

  return (
    <>
      <ReactPlayer
        ref={playerRef}
        controls={false}
        loop={loop}
        url={videoUrl}
        height="80%"
        width="100%"
        onReady={handleReady}
        playing={playing}
        playbackRate={speed}
        style={{
          visibility: isReady ? "visible" : "hidden",
          maxWidth: "80%"
        }}
        progressInterval={100}
        onEnded={handleEnd}
      />
      <Paper 
        variant="outlined"
        style={{
          display: 'flex',
          marginTop: '1rem'
        }} 
        square
      >
        <IconButton
          size="large"
          color="default"
          onClick={() => skipTime(-10)}
          sx={{
            "&:active": {
              color: "info.main",
            }
          }}
        >
          <Replay10 />
        </IconButton>
        <IconButton
          size="large"
          color="default"
          onClick={() => skipTime(-5)}
          sx={{
            "&:active": {
              color: "info.main",
            }
          }}
        >
          <Replay5 />
        </IconButton>
        <IconButton
          size="large"
          color={playing ? "info" : "default"}
          onClick={() => setPlaying(!playing)}
        >
          {!playing ? <PlayArrow /> : <Pause />}
        </IconButton>
        <IconButton
          size="large"
          color="default"
          onClick={() => skipTime(5)}
          sx={{
            "&:active": {
              color: "info.main",
            }
          }}
        >
          <Forward5 />
        </IconButton>
        <IconButton
          size="large"
          color="default"
          onClick={() => skipTime(10)}
          sx={{
            "&:active": {
              color: "info.main",
            }
          }}
        >
          <Forward10 />
        </IconButton>
        <Divider orientation="vertical" />
        <IconButton
          size="large"
          color={loop ? "info" : "default"}
          onClick={() => setLoop(!loop)}
          sx={{
            "& .MuiSvgIcon-root": {
              textDecoration: loop ? "none" : "line-through",
            },
          }}
        >
          <Repeat />
        </IconButton>
        <Divider orientation="vertical" />
        <RadioGroup row style={{ display: "flex", alignItems: "center", padding: '0rem 0rem 0rem 0.5rem' }}>
          <Speed style={{ marginRight: '0.6rem' }} />
          {VIDEO_SPEED.map((rate, i) => (
            <FormControlLabel key={i} value={rate.speed} checked={rate.speed === speed} onClick={() => setSpeed(rate.speed)} control={<Radio color="info" />} label={rate.speed} />
          ))}
        </RadioGroup>
        <Divider orientation="vertical" />
        <IconButton
          size="large"
          onClick={() => handleDownload(videoUrl, getFileName(date))}
          sx={{
            "&:active": {
              color: "info.main",
            }
          }}
        >
          <Download />
        </IconButton>
      </Paper>
    </>
  );
}
